




























import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchPasswordRecovery } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public valid = true;
  public userId: string = '';
  public appName = appName;

  public cancel() {
    this.$router.back();
  }

  public submit() {
    if (this.userId) {
      dispatchPasswordRecovery(this.$store, { userId: this.userId });
    }
  }
}
